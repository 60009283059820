<template>
  <div class="main">
    <el-form :label-position="'left'" label-width="80px" style="margin: 20px; width: 600px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="时间(必填)">
            <el-date-picker v-model="fromDay" value-format="yyyy-MM-dd" type="date" placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item>
            <el-time-select
              placeholder="选择时间"
              v-model="fromTime"
              :picker-options="{
                start: '00:00',
                step: '01:00',
                end: '23:00',
              }"
            >
            </el-time-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item>
            <el-date-picker v-model="toDay" value-format="yyyy-MM-dd" type="date" placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item>
            <el-time-select
              placeholder="选择时间"
              v-model="toTime"
              :picker-options="{
                start: '00:00',
                step: '01:00',
                end: '23:00',
              }"
            >
            </el-time-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="课程id">
            <el-input v-model="queryCourseId"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="手机号">
            <el-input v-model="queryPhone"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="已批改">
            <el-switch v-model="hasReview" active-text="已批改" inactive-text="未批改"> </el-switch>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="button-row">
        <el-form-item>
          <el-button type="primary" @click="onQuery">查询</el-button>
        </el-form-item>
      </el-row>
    </el-form>
    <div class="table">
      <el-table :data="tableData" highlight-current-row>
        <el-table-column label="id" width="200">
          <template slot-scope="scope">
            <div v-html="scope.row.id"></div>
          </template>
        </el-table-column>
        <el-table-column prop="courseId" label="courseId" width="100"></el-table-column>
        <el-table-column label="userPhone" width="200">
          <template slot-scope="scope">
            <div v-html="scope.row.userPhone"></div>
          </template>
        </el-table-column>
        <el-table-column prop="count" label="count" width="100"></el-table-column>
        <el-table-column label="title" width="200">
          <template slot-scope="scope">
            <div v-html="scope.row.title"></div>
          </template>
        </el-table-column>
        <!-- <el-table-column label="userPhoneV2">
          <template slot-scope="scope">
            <div v-html="scope.row.userPhoneV2"></div>
          </template>
        </el-table-column> -->
        <!-- <el-table-column label="courseId" width="100">
          <template slot-scope="scope">
            <div v-html="scope.row.courseId"></div>
          </template>
        </el-table-column> -->
        <el-table-column label="hasReview" width="100">
          <template slot-scope="scope">
            <div v-html="scope.row.hasReview"></div>
          </template>
        </el-table-column>
        <el-table-column prop="startTime" :formatter="formatTime" label="开始时间" width="200"></el-table-column>
        <el-table-column prop="endTime" :formatter="formatTime" label="结束时间" width="200"></el-table-column>
        <el-table-column label="操作" width="250">
          <template slot-scope="scope">
            <el-button size="small" @click="onView(scope.$index, scope.row)">浏览</el-button>
            <el-button size="small" @click="onEdit(scope.$index, scope.row)">编辑</el-button>
            <el-button type="danger" size="small" @click="onDelete(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import msgLogger from "@/util/msgLogger";
import { userHomeWorkApi } from "@/api/userHomeWorkApi";
import { strToEpoch } from "@/util/timeTool.js";
import { toNumber } from "@/util/numberUtil.js";
import { epochToDateString } from "@/util/TimeUtil";

export default {
  components: {},
  data() {
    return {
      aidaUser: null,
      queryPhone: "",
      queryCourseId: "",
      hasReview: false,

      fromDay: "",
      fromTime: "",
      toDay: "",
      toTime: "",

      tableData: [],
    };
  },
  methods: {
    async onQuery() {
      this.tableData = [];
      let ret = null;
      let err = null;
      let qUserId = 0;
      let qCourseId = toNumber(this.queryCourseId, 0);

      if (this.queryPhone) {
        [ret, err] = await userHomeWorkApi.awaitChain(userHomeWorkApi.getAidaUserByPhone(this.queryPhone));
        if (ret && ret.code == 0 && ret.data) {
          qUserId = ret.data.userId;
        } else {
          msgLogger.error("系统出错" + err);
        }
      }
      let startStr = this.fromDay + " " + this.fromTime;
      let startTime = strToEpoch(startStr);
      let endStr = this.toDay + " " + this.toTime;
      let endTime = strToEpoch(endStr);
      console.log("time, userId, courseId, hasReview", startTime, endTime, qUserId, qCourseId, this.hasReview);

      if (startTime <= 0 || endTime <= 0) {
        msgLogger.warn("开始、结束时间必须有");
        return;
      }
      [ret, err] = await userHomeWorkApi.awaitChain(userHomeWorkApi.queryCount(startTime, endTime, qCourseId, qUserId));
      console.log("query count", ret);
      let countDct = {};
      if (ret.code == 0) {
        countDct = ret.data;
      }
      [ret, err] = await userHomeWorkApi.awaitChain(
        userHomeWorkApi.query(startTime, endTime, qCourseId, qUserId, this.hasReview)
      );
      console.log("query", ret);
      if (ret && ret.code == 0) {
        for (let item of ret.data) {
          let newKey = item.user.userId + ":" + item.homeWork.courseId;
          let count = countDct[newKey] || 0;

          this.tableData.push({
            id: item.homeWork.id,
            title: item.homeWork.title,
            userPhone: item.user.userPhone,
            userPhoneV2: this.phonePrivacy(item.user.userPhone),
            courseId: item.homeWork.courseId,
            startTime: item.homeWork.startTime,
            endTime: item.homeWork.endTime,
            hasReview: item.homeWork.hasReview,
            count: count,
          });
        }
        console.log("table data", this.tableData);
      } else {
        msgLogger.error("系统出错");
      }
    },
    onView(index, row) {
      let path = `/user-home-work/view?id=${row.id}`;
      let routeData = this.$router.resolve({ path: path });
      window.open(routeData.href, "_blank");
    },
    onEdit(index, row) {
      let path = `/user-home-work/edit?id=${row.id}`;
      let routeData = this.$router.resolve({ path: path });
      window.open(routeData.href, "_blank");
    },
    onDelete(index, row) {
      let id = row.id;
      let msg = "确定删除该项吗？";
      this.$confirm(msg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          userHomeWorkApi.deleteById(id).then((ret) => {
            console.log(ret);
            if (ret.code == 0 && ret.data) {
              msgLogger.done("成功地删除了用户作业");
              this.onQuery();
            } else {
              msgLogger.error(ret.msg);
            }
          });
        })
        .catch(() => {});
    },

    phonePrivacy(phone) {
      return phone.slice(0, 4) + "****" + phone.slice(7);
    },
    formatTime: function(row, column) {
      let num = Number(row[column.property]);
      return epochToDateString(num);
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  .box-card {
    text-align: left;
    margin: 20px 0px;
  }
  .button-row {
    text-align: right;
    margin: 20px;
  }
  .table {
    width: 90%;
  }
}
</style>
